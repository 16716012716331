import React, { FC } from "react"
import cn from "classnames"
import styles from "./title.module.css"

interface TitleProps {
  className?: string
}

const Title: FC<TitleProps> = ({ className }) => (
  <div className={cn(styles.component, className)}>
    <h1>{"Masterarbeit"}</h1>
    <h2>{"Kunst in der virtuellen Realität"}</h2>
  </div>
)

export default Title
