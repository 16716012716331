import React, { FC } from "react"
import noop from "lodash/noop"
import styles from "./text-input.module.css"
import { TextInputProps, TextInputType } from "./text-input.props"

const TextInput: FC<TextInputProps> = ({
  inputType = TextInputType.Text,
  placeholder,
  name,
  value,
  onChange = noop,
}) => (
  <div className={styles.component}>
    <label className={styles.label}>
      <div className={styles.inputWrapper}>
        <input
          className={styles.input}
          type={inputType}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={evt => onChange(evt.target.value)}
        />
      </div>
    </label>
  </div>
)

export default TextInput
