import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { checkIsLoggedIn } from "../services/auth"
import { Route } from "../constants/routes"
import Intro from "../components/intro"

const IndexPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(checkIsLoggedIn())
  if (isLoggedIn) {
    navigate(Route.Chapters)
    return null
  }
  return (
    <Layout>
      <SEO title="Home" />
      <Intro onLogin={setIsLoggedIn} />
    </Layout>
  )
}

export default IndexPage
