import React, { FC } from "react"
import styles from "./intro.module.css"
import Title from "./title/title"
import Login from "./login/login"

interface IntroProps {
  onLogin: (success: boolean) => void
}

const Intro: FC<IntroProps> = ({ onLogin }) => (
  <div className={styles.component}>
    <img
      className={styles.bubble}
      src="start-bubble.jpg"
      alt="Leuchtender Bubbel an der Decke."
    />
    <Title className={styles.title} />
    <Login className={styles.login} onLogin={onLogin} />
    <img
      className={styles.keyvisual}
      src="start-tina.jpg"
      alt="Tina als VR-Orakel."
    />
  </div>
)

export default Intro
