export enum TextInputType {
  Text = "text",
  Email = "email",
  Password = "password",
}

export interface TextInputProps {
  inputType?: TextInputType
  name?: string
  value: string
  placeholder?: string
  onChange?: (value: string) => void
}
