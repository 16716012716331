import React, { FC, useState, FormEvent } from "react"
import noop from "lodash/noop"
import cn from "classnames"
import { RouteComponentProps } from "@reach/router"
import { handleLogin } from "../../../services/auth"
import styles from "./login.module.css"
import TextInput from "../../text-input"
import { TextInputType } from "../../text-input/text-input.props"
import Spinner from "../../spinner"

interface LoginProps extends RouteComponentProps {
  onLogin?: (success: boolean) => void
  className?: string
}

const Login: FC<LoginProps> = ({ onLogin = noop, className }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [hasFailed, setHasFailed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const resetCredentials = () => {
    setUsername("")
    setPassword("")
  }

  const handleLoginFailure = () => {
    onLogin(false)
    resetCredentials()
    setHasFailed(true)
    setIsSubmitting(false)
  }

  const handleLoginSuccess = () => {
    onLogin(true)
    setHasFailed(false)
    setIsSubmitting(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)
    try {
      const success = await handleLogin({ username, password })
      success ? handleLoginSuccess() : handleLoginFailure()
    } catch (e) {
      handleLoginFailure()
    }
  }

  return (
    <form
      className={cn(styles.component, className)}
      method="post"
      onSubmit={handleSubmit}
      noValidate
    >
      <TextInput
        value={username}
        inputType={TextInputType.Text}
        name="username"
        placeholder="Nutzername"
        onChange={setUsername}
      />
      <TextInput
        value={password}
        inputType={TextInputType.Password}
        name="password"
        placeholder="Kennwort"
        onChange={setPassword}
      />
      {isSubmitting ? (
        <Spinner small />
      ) : (
        <input type="submit" value="anmelden" className="btnBase btnPrimary" />
      )}
      {hasFailed && (
        <p className={cn("error", styles.error)}>
          {"Login fehlgeschlagen... Bitte prüfen Sie ihre Anmeldedaten."}
        </p>
      )}
    </form>
  )
}

export default Login
